
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { useMessages } from "context/messages";
import { getToken } from "context/auth";

import { ReactComponent as PermitLargeIcon } from 'assets/icons/ico_permits_large.svg';

import PermitsTable from "components/pageSpecific/permits/permitsTable";
import Widget from "components/dashboard/widget";
import ButtonText from "components/common/buttonText";
import WidgetLoadingSkeleton from "components/dashboard/widgetLoadingSkeleton";
import InputSearch from "components/common/inputSearch";

import { getPermitsForParkingArea, getPermitStatisticsForParkingArea } from "api/permits";
import DropDownMultiSelector from "components/common/dropDownMultiSelector";
import VideoInstructions from "components/common/videoInstructions";

const PermitsPage = () => {

    const { t } = useTranslation();
    const { showToast } = useMessages();
    const [permits, setPermits] = useState();
    const [isLoading, setIsLoading] = useState();
    const { organizationExtId, parkingAreaExtId } = useParams();
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState();
    const [filteredPermits, setFilteredPermits] = useState();
    const [filterPermitOptions, setFilterPermitOptions] = useState([
        { title: t('permitsPage.expired'), value: 'expired', isChecked: false },
        { title: t('permitsPage.unpaid'), value: 'unpaid', isChecked: false },]);
    const [statistics, setStatistics] = useState();
    const [noFilterResults, setNoFilterResults] = useState(false);

    useEffect(() => {
        const load = async () => {
            setIsLoading(true);

            try {
                const token = await getToken();
                const result = await getPermitsForParkingArea(token, parkingAreaExtId);
                const statisticsResult = await getPermitStatisticsForParkingArea(token, parkingAreaExtId);

                if (!statisticsResult.error) {
                    setStatistics(statisticsResult);
                } else {
                    console.error(statisticsResult);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }

                if (!result.error) {
                    setPermits(result);
                } else {
                    console.error(result);
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }

            setIsLoading(false);
        }

        load();
    }, [parkingAreaExtId]);

    useEffect(() => {
        if (permits) {
            let shouldFilter = false;

            filterPermitOptions.forEach((option) => {
                if (option.isChecked) {
                    shouldFilter = true;
                }
            });

            let filteredPermits = [...permits];

            if (!shouldFilter) {
                /* Exclude expired permits by default */
                filteredPermits = [...permits.filter((permit) => {
                    return permit.status !== 'expired';
                })];
            }

            if (shouldFilter) {
                filteredPermits = [...filteredPermits.filter((permit) => {
                    if (filterPermitOptions[0].isChecked && permit.status === 'expired') {
                        return true;
                    }

                    if (filterPermitOptions[1].isChecked && permit.paymentStatus.toLowerCase() !== 'paid') {
                        return true;
                    }
                })];
            }

            if (searchText) {
                filteredPermits = [...filteredPermits.filter((permit) => {
                    if (permit.description?.toLowerCase().includes(searchText.toLowerCase())) {
                        return true;
                    }

                    if (permit.spotIdentifier?.toLowerCase().includes(searchText.toLowerCase())) {
                        return true;
                    }

                    if (permit.firstName?.toLowerCase().includes(searchText.toLowerCase())) {
                        return true;
                    }

                    if (permit.lastName?.toLowerCase().includes(searchText.toLowerCase())) {
                        return true;
                    }

                    if (permit.phone?.toLowerCase().includes(searchText.toLowerCase())) {
                        return true;
                    }

                    if (permit.registrationIdentifier?.toLowerCase().includes(searchText.toLowerCase())) {
                        return true;
                    }
                })];
            }

            setNoFilterResults(filteredPermits?.length === 0 && permits?.length > 0);
            setFilteredPermits(filteredPermits);
        }
    }, [filterPermitOptions, searchText, permits]);

    const handleFilterPermits = (option) => {
        const newOptions = [...filterPermitOptions];
        const optionIndex = newOptions.findIndex((opt) => opt.value === option.value);
        newOptions[optionIndex].isChecked = !newOptions[optionIndex].isChecked;
        setFilterPermitOptions(newOptions);
    }

    return (
        <>
            <Widget>
                <div className="flex md:flex-row flex-col-reverse gap-12 justify-between items-center">
                    <div className="flex flex-col">
                        <h2 className="text-xl font-medium mb-3">{t('permitsPage.permitsPageTitle')}</h2>
                        <span>{t('permitsPage.permitsPageDescription')}</span>
                    </div>
                    <span className="flex w-20 h-20">
                        <PermitLargeIcon />
                    </span>
                </div>
            </Widget>
            {isLoading &&
                <div className="mt-6">
                    <WidgetLoadingSkeleton />
                </div>
            }
            {!isLoading &&
                <>
                    <div className="flex flex-col md:flex-row mt-6 gap-6">
                        <Widget className="md:w-1/3">
                            <h2 className="text-center">{t('permitsPage.activePermits')}</h2>
                            <span className="text-center text-xl font-medium mt-3">{statistics?.numberOfActivePermits ?? '0'}</span>
                        </Widget>
                        <Widget className="md:w-1/3">
                            <h2 className="text-center">{t('permitsPage.parkingSpotsWithoutPermits')}</h2>
                            <span className="text-center text-xl font-medium mt-3">{statistics?.numberOfAvailableParkingSpots ?? '0'}</span>
                        </Widget>
                        <Widget className="md:w-1/3">
                            <h2 className="text-center">{t('permitsPage.permitsInMarketplace')}</h2>
                            <span className="text-center text-xl font-medium mt-3">{statistics?.numberOfPermitsInMarketplace ?? '0'}</span>
                        </Widget>
                    </div>
                    <div className="mt-6">
                        <VideoInstructions title={t('generic.videoInstructions')} description={t('permitsPage.videoInstructionsDescription')} videoUrl='https://www.youtube.com/watch?v=BUv7FOlfYvk' />
                    </div>
                    <Widget className="mt-6">
                        <div className="flex items-center justify-between mb-6">
                            <h2 className="text-xl font-medium">{t('permitsPage.addPermitTitle')}</h2>
                            <div className="flex items-center gap-3">
                                <div className="hidden md:flex gap-3">
                                    <InputSearch placeHolder={t('generic.search')} value={searchText} onChange={(value) => { setSearchText(value); }} />
                                    <DropDownMultiSelector
                                        options={filterPermitOptions}
                                        onOptionSelected={(option) => { handleFilterPermits(option); }}
                                    />
                                </div>
                                <ButtonText onClick={() => { navigate(`/${organizationExtId.toLowerCase()}/parking-areas/${parkingAreaExtId}/create-permit`) }}>
                                    {t('permitsPage.createPermit')}
                                </ButtonText>
                            </div>
                        </div>
                        <PermitsTable
                            permits={filteredPermits}
                            setPermits={setFilteredPermits}
                            isMarketplace={false}
                            noResult={noFilterResults} />
                    </Widget>
                </>
            }
        </>
    )
}

export default PermitsPage;