import Widget from "components/dashboard/widget";
import React, { useEffect, useState } from "react";
import { ReactComponent as QueueLargeIcon } from 'assets/icons/ico_queue_large.svg';
import WidgetLoadingSkeleton from "components/dashboard/widgetLoadingSkeleton";
import { useNavigate, useParams } from "react-router-dom";
import { useMessages } from "context/messages";
import { useTranslation } from "react-i18next";
import { getPermitQueue } from "api/permitQueue";
import { getToken } from "context/auth";
import PermitQueueTable from "components/pageSpecific/permitQueue/permitQueueTable";

const PermitQueuePage = () => {

    const { t } = useTranslation();
    const { showToast } = useMessages();
    const [isLoading, setIsLoading] = useState(true);
    const { parkingAreaExtId } = useParams();
    const [queueItems, setQueueItems] = useState();

    useEffect(() => {

        const load = async () => {
            try {
                const token = await getToken();
                const result = await getPermitQueue(token, parkingAreaExtId);

                if (result && !result.error) {
                    setQueueItems(result);
                } else {
                    console.error(result);
                    showToast(t('generic.errorTitle'), t('loginPage.emailIsNotValid'), 'error');
                }
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }

            setIsLoading(false);
        }

        load();
    }, [])

    return (
        <>
            <Widget>
                <div className="flex md:flex-row flex-col-reverse gap-12 justify-between items-center">
                    <div className="flex flex-col">
                        <h2 className="text-xl font-medium mb-3">{t('permitQueuePage.title')}</h2>
                        <span>{t('permitQueuePage.description')}</span>
                    </div>
                    <span className="flex w-20 h-20">
                        <QueueLargeIcon />
                    </span>
                </div>
            </Widget>
            {isLoading &&
                <div className="mt-6">
                    <WidgetLoadingSkeleton />
                </div>
            }
            {!isLoading &&
                <Widget className="mt-6">
                    <div className="flex items-center justify-between mb-6">
                        <h2 className="text-xl font-medium mb-3">{t('permitQueuePage.usersInQueue')}</h2>
                    </div>
                    <PermitQueueTable
                        queueItems={queueItems}
                        setQueueItems={setQueueItems} />
                </Widget>
            }
        </>
    )
}

export default PermitQueuePage;