const BASE_URL_PERMITS = `${process.env.REACT_APP_API_PERMITS_BASE_URL}/PermitQueue`;

export const getPermitQueue = async (token, parkingAreaExtId) => {
  const response = await fetch(BASE_URL_PERMITS + `/get-queue/${parkingAreaExtId.toLowerCase()}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const getPermitQueueItem = async (token, permitQueueItemExtId) => {
  const response = await fetch(BASE_URL_PERMITS + `/get-queue-item/${permitQueueItemExtId.toLowerCase()}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const removeFromQueue = async (token, permitQueueItemExtId) => {
  const response = await fetch(BASE_URL_PERMITS + `/remove-from-queue/${permitQueueItemExtId.toLowerCase()}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}